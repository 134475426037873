import * as Sentry from "@sentry/react";

import { envConstants, LOCALHOST } from "Constants";
import { sentryErrorHelper } from "Utils";

const ignoredErrors: string[] = [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Expected to find a valid target",
    "null is not an object (evaluating 'g.readyState')",
    // This error occurs when a resource (javascript) fails to load, likely due to a network error.
    // More discussion about this https://github.com/getsentry/sentry-javascript/issues/6945
    "Unexpected token '<'",
    // This occurs when a user is using chrome built-in Google translation. We can't
    "String contained an illegal UTF-16 sequence.",
    // We got planty of these errors, but only for one customer https://github.com/qopla/fronty/issues/3453
    // Couldn't reproduce it, so we are ignoring it for now
    "Invalid or unexpected token",
    // Prevent some errors from the console
    "Warning: Each child in a list should have a unique",
    "Warning: Can't perform a React state update on a component that hasn't mounted yet",
    "High memory usage detected",
    "Warning: Encountered two children with the same key",
    "Warning: React does not recognize the"
];

const getSentryErrorHelper = (
    event: Sentry.ErrorEvent,
    _: Sentry.EventHint
): PromiseLike<Sentry.ErrorEvent | null> | Sentry.ErrorEvent | null => {
    const additionalEventData = sentryErrorHelper(event);
    if (envConstants.BACKEND_URL === LOCALHOST) {
        /** Added this in Localhost for useful logs in developing */
        const message = event?.message;
        const isIgnoredError = ignoredErrors.some(error => message?.includes(error));
        if (isIgnoredError) {
            console.log("Ignored Sentry error detected:", additionalEventData);
        } else {
            console.log("Sentry Local Event: ", additionalEventData);
        }
    }
    return additionalEventData;
};

Sentry.init({
    ignoreErrors: ignoredErrors,
    dsn: envConstants.BACKEND_URL !== LOCALHOST ? envConstants.SENTRY_DSN : undefined,
    release: envConstants.VERSION,
    environment: envConstants.BETA ? `${envConstants.PROFILE}BETA` : envConstants.PROFILE,
    beforeSend: getSentryErrorHelper,
    tracesSampleRate: envConstants.PROFILE === "production" ? 0.1 : 1.0,
    integrations: [],
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true
});

/*   
TESTING IN LOCALHOST  
    dsn: "Get the dsn link",
    release: envConstants.VERSION,
    environment: "DEVTEST", 
*/

/** Worked better to add the integration after the init  */

/**
 * This integration captures breadcrumbs from the browser.
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/breadcrumbs/
 */
Sentry.addIntegration(
    Sentry.breadcrumbsIntegration({
        console: false, // We don't want to log console logs
        dom: true,
        fetch: true, // We want to log fetch requests
        history: true, // log navigation events
        xhr: false
    })
);

/**
 * This integration captures console logs - Configured here for only Error.
 * e.g try catch with console.error can be captured.
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/captureconsole/
 */
Sentry.addIntegration(Sentry.captureConsoleIntegration({ levels: ["error"] }));

/**
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/browsertracing/
 */
Sentry.addIntegration(Sentry.browserTracingIntegration());

/**
 * Extracts all non-native attributes from the error object and attaches them to the event as extra data.
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/extraerrordata/
 */
Sentry.addIntegration(Sentry.extraErrorDataIntegration());

/**
 * This integration captures errors on failed requests from Fetch and XHR and attaches request and response information.
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/httpclient/
 */
Sentry.addIntegration(Sentry.httpClientIntegration());

/**
 * SOURCE MAPS - Currently we use commands to upload the source maps to Sentry
 * We can use a web config plugin to upload the source maps to Sentry
 * https://docs.sentry.io/platforms/javascript/guides/react/sourcemaps/uploading/typescript/
 */
